.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;

  max-height: calc(100vh - 100px);
  min-height: calc(100vh - 200px);
  width: 65%;
  height: 100%;
  overflow: scroll;
  border-radius: 5px;
  background-color: var(--primary-bg);
  z-index: 5000;
  margin-top: 3em;
}

.buttonOutlineDark {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  color: var(--text-main);

  margin-bottom: 0rem;
  font-size: 16px;
  border-radius: 3px;
  white-space: nowrap;
  border: 1px solid var(--text-main);
  background-color: transparent;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.1s ease;
}

.buttonOutlineDark:hover {
  opacity: 0.8;
  background-color: var(--primary-bg-light);
  scale: 1.01;
}

.buttonOutlineDark:active {
  scale: 0.99;
}

.buttonFile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  color: var(--text-main);
  margin-bottom: 0rem;
  border-radius: 3px;

  font-size: 14px;
  height: 47px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 5px 10px;
  white-space: nowrap;
  background-color: var(--text-main);
  color: white;
  cursor: pointer;
  transition: all 0.1s ease;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.modalImg {
  width: 100%;
  aspect-ratio: 5/1;
  object-fit: cover;
}

.modalBody {
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  padding-bottom: 2rem;
}

.explainText {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.paragraph {
  font-size: 14px;
  color: var(--text-body);
  margin-bottom: 0;
  line-height: 150%;
  white-space: pre-wrap;
}

.planDetails {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  width: 100%;
}

.plan {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
  padding: 1.5rem;
  border: 1px solid var(--primary-border);
  border-radius: 5px;
  background-color: white;
  transition: all 0.3s ease;
}

.subPlanTitle {
  font-size: 20px;
  font-weight: 500;
  color: var(--primary-text);
  font-family: "Editorial";
}

.subPlanDescription {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.threshhold {
  font-size: 14px;
  color: var(--text-main);
  font-weight: 500;
}

.price {
  font-size: 14px;
  color: var(--text-body);
}

.subPlanText {
  font-size: 12px;
  margin-bottom: 0rem;
  color: var(--text-body);
}

.options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  justify-content: center;
}

.optionsTitle {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0rem;
}

.optionsList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
}

.option {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
  columns: var(--text-body);
}

.checkMark {
  color: var(--primary-purple);
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
}
.title {
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 130%;
}

.buttons {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  margin-top: auto;
}

.rightButtons {
  display: flex;
  gap: 5px;
}

.buttonBack {
  background-color: var(--primary-bg);
  color: var(--text-main);
  border: 1px solid var(--primary-border);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 14px;

  cursor: pointer;
  margin-right: auto;
}

.buttonBack:hover {
  background-color: var(--primary-border);
  color: var(--text-main);
}
.buttonForward {
  background-color: var(--primary-purple);
  color: white;
  border: 1px solid var(--primary-purple);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.buttonForward:hover {
  opacity: 0.8;
  color: white;
}

.input {
  height: 47px;
  position: relative;
  cursor: pointer;
  -webkit-margin-start: auto;
  border: 1px solid var(--primary-border);
  border-radius: 2px;
  -webkit-margin-end: auto;
  margin-inline: auto;
  font-size: 14px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin-bottom: 0rem;
  padding: 12px 20px;
  transition: all 0.3s ease;
}

.input:focus {
  border: 1px solid var(--primary-purple);
  outline: none;
}

.col {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.25rem;
}

.fileInputWrapper {
  height: 47px;
  position: relative;
  cursor: pointer;
  border: 1px solid var(--primary-border);
  border-radius: 2px;
  font-size: 14px;
  background-color: white;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 0rem;
  padding: 12px 20px;
  padding-right: 0;
  transition: all 0.3s ease;
  flex-direction: row;
}

.previewImg {
  height: 100px;
  width: 100px;
}

.fileInput {
  display: none;
}

.label {
  font-size: 14px;
  color: var(--text-body);
  margin-bottom: 0rem;
}

.muteText {
  font-size: 12px;
  color: var(--text-mute);
  margin-bottom: 0rem;
}

.row {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 1rem;
}

@media (max-width: 600px) {
  .modal {
    width: 90%;
    height: 100%;
  }

  .modalContent {
    height: 100%;
  }

  .planDetails {
    grid-template-columns: 1fr;
  }

  .buttons {
    padding-bottom: 2rem;
  }
}
