.bg {
  background-color: white;
  min-height: 100vh;
  padding-bottom: 4rem;
}

.checkMark {
  color: var(--primary-green-500);
}

.dateHeader {
  color: var(--text-body);
}
.timeSlot {
  display: flex;
  align-items: center;
  background-color: white;
  font-size: 14px;
  padding: 0.2rem 0.5rem;
  width: fit-content;
  border-radius: 7px;
  border: 0.5px solid rgba(128, 128, 128, 0.734);
  cursor: pointer;
}

.timeSlotSelected {
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: white;
  font-size: 14px;
  padding: 0.2rem 0.5rem;
  border-radius: 7px;
  border: 0.5px solid #68b368;
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
  position: relative;
  cursor: pointer;
}

.selectedBubble {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #68b368;
  border-radius: 100%;
  width: 0.8em;
  height: 0.8em;
}

.chackoutPage {
  max-width: 1452px;
  padding-left: 5rem;
  padding-right: 5rem;
  margin-left: auto;
  margin-right: auto;
}

.discountCodeBox {
  width: 100%;
  border: none;
  background-color: var(--primary-bg);
  padding: 10px;
}

.discountCodeBox::placeholder {
  font-size: 14px;
}

.discountCodeBox:focus {
  outline: 1px solid var(--primary-purple);
}

.applyButton {
  background-color: var(--primary-purple);
  border: none;
  color: white;
  font-size: 14px;
}

.applyButtonNonActive {
  background-color: var(--primary-purple) 70;
  border: none;
  color: white;
  font-size: 14px;
}

.promoContainer {
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.qty {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: auto;
  padding-left: 0.5rem;
}

.showBox1 {
  background-color: white;
  padding: 1rem;
  padding-bottom: 0rem;
}

.add {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #99a3a6;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  border: 0.5px solid #99a3a6;
  padding: 5px;
  cursor: pointer;
}

.quantityContainer {
  padding-left: 1rem;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.totalQty {
  color: #99a3a6;
  margin-left: 0.7rem;
  margin-right: 0.7rem;
}

.datePickerInner {
  cursor: pointer;
  width: inherit;
}

.checkoutHeader {
  padding-top: 3.25rem;
  margin-bottom: 2rem;

  font-size: 32px;
  font-weight: 500;
}

.leftSide {
  width: 60%;
  display: inline-block;
}

.space {
  padding-top: 1rem;
}
.space2 {
  position: relative;
  padding-top: 1.5rem;
}
.box {
  position: relative;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.box1 {
  border-bottom: 2px solid var(--primary-bg);
}

.showBox {
  background-color: var(--primary-bg-light);
  border: 1px solid var(--primary-border);
  display: flex;
  flex-direction: column;
  gap: 1rem;

  border-radius: 8px;

  margin-top: 1rem;
  padding: 1rem;
}

.select {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
}

.space1 {
  margin-right: 1rem;
}

.continueButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 100%;
  height: 3rem;
  background-color: var(--text-main);
  color: white;
  margin-top: 1rem;
  border-radius: 3px;
}

.continueButton:hover {
  opacity: 0.8;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
  transform: translateY(-1px);
}

.selectBox {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid rgb(232 234 239);
  font-size: 13px;
  border-radius: 5px;
  height: 3rem;

  width: 45%;
}

.selectBox:hover {
  border: 1px solid var(--primary-purple);
  cursor: pointer;
}

.input {
  padding-left: 1rem;
  padding-right: 1rem;
  height: 3rem;
  width: 45%;
  border: 1px solid rgb(232 234 239) !important;

  background-color: white;
}

.input::placeholder {
  font-size: 14px;
}

.inputRed {
  padding-left: 1rem;
  padding-right: 1rem;
  height: 3rem;
  width: 45%;
  border: 0px;
  background-color: white;
  border: 1px dashed var(--primary-red-400);
}
.mainContainer {
  width: 35%;
  display: flex;
  margin-left: 2.5rem;

  flex-direction: column;
}

.errorDate {
  color: var(--primary-red-400);
  padding: 1em;
  font-size: 16px;
  border: 1px dashed var(--primary-red-400);
}

.input1 {
  padding-left: 1rem;
  padding-right: 1rem;
  min-height: 8rem;
  width: 100%;
  border: 1px solid rgb(232 234 239) !important;
  background-color: white !important;
  font-size: 14px;
}

.input1::placeholder {
  font-size: 14px;
}

.selectBoxError {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px dashed var(--primary-red-400);
  font-size: 13px;
  height: 3rem;
  border-radius: 5px;

  width: 45%;
}

.selectBoxError:hover {
  border: 1px solid var(--primary-purple);
  cursor: pointer;
}

.selectBoxActive {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 5px;

  border: 1px solid var(--primary-purple);
  color: var(--text-main);
  font-size: 13px;
  height: 3rem;

  width: 45%;
}

.selectBoxActive:hover {
  border: 1px solid var(--primary-purple);
  cursor: pointer;
}

.mainText1 {
  position: relative;
  display: flex;
  width: fit-content;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0rem;
}

.mainText2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  background-color: rgba(241, 250, 238, 0.321);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0rem;
}

.mainText {
  background-color: var(--primary-bg-light);
  border: 1px solid var(--secondary-gray);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;

  font-weight: 600;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0rem;
}

.mainTextActive {
  background-color: var(--primary-bg-light);
  border: 1px solid var(--primary-green-200);
  color: var(--text-main);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;

  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0rem;
}

.mainSubText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

.timePickerText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-bottom: 0rem;
}

.subText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-bg);
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0rem;
  font-size: 14px;
}

.subTextAddress {
  font-size: 14px;
  color: var(--text-body);
  font-weight: 200;
  margin-bottom: 0rem;
}

.subSubText {
  color: #222222;
  margin-bottom: 0rem;
  font-weight: 300;
  font-size: 14px;
}

.subSubTextGrey {
  color: #555555;
}

.reqIcon {
  color: var(--primary-purple);
}

.borderBox {
  position: relative;

  padding-bottom: 1.5rem;
}
.borderBox1 {
  position: relative;
  padding-bottom: 0rem;
}

.editIcon {
  width: 2rem;
  height: 2rem;
  border-radius: 2px;
  margin-bottom: 5px;
  margin-top: 5px;

  padding: 2px;
  color: black;
}

.mainTextSummary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

.checkoutSummary {
  width: 30%;
  margin-left: 2.5%;
  display: inline-block;
  min-height: 10em;
  vertical-align: top;
}

.subTextMain {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subTextMainStrike {
  color: rgb(0, 148, 37);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: line-through;
}

.total {
  font-weight: 600;
  font-size: 16px;
  border-top: 1px solid black;
  padding-top: 1rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sellerBatch {
  background-color: white;
}

.whiteBox {
  background-color: white;
  padding: 1rem;
  margin-bottom: 1rem;
}

.infoBox {
  background-color: white;
  margin-bottom: 1rem;
}
.pickUpInfo {
  font-weight: 600;
  background-color: rgb(241, 250, 238);
  padding: 1rem;
}

.listingSummary {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.prices {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1rem;
  font-size: 14px;
  border-bottom: 1px solid #99a3a6;
}

.finalPrices {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1rem;
  font-size: 14px;
}

.listingInfo {
  padding-left: 1rem;
  display: inline-block;
  font-size: 14px;
}

.color {
  color: var(--primary-purple) !important;
}

.listingImg {
  position: relative;
  object-fit: cover;
  width: 4rem;
  height: 4rem;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.deliveryInfo {
  margin-bottom: 1em;
  position: relative;
  cursor: pointer;
}

.deliveryInfoDisabled {
  margin-bottom: 1em;
  position: relative;
}

.listingDisplay {
  display: flex;
  flex-direction: row;
  position: relative;
}

.listingImgDisplay {
  margin-right: -1rem;
  width: 5rem;
  height: 5rem;
  object-fit: cover;
  border: 1px solid var(--primary-bg);
}

.batchDeliveryInfo {
  width: 65%;
  display: inline-block;
}

.timesInputs {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.timesInputsBox {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
}

.col {
  display: flex;
  flex-direction: column;
}

.pointer {
  cursor: pointer;
  position: relative;
  color: black;
  min-width: 100%;
  margin-block: 5px;
  padding: 10px;
  background-color: white;
  border: 1px solid rgb(232 234 239);
}

.pointerError {
  cursor: pointer;
  position: relative;
  color: black;
  min-width: 100%;
  margin-block: 5px;
  padding: 10px;
  background-color: var(--primary-bg);
  border: 1px dashed var(--primary-red-400);
}

.react-datepicker__time-list-item--disabled {
  display: none !important;
}

/* Layout */

.container {
  display: flex;
  width: 70%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
  margin: auto;
  margin-top: 20px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.row {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
}

.deliveryChoice {
  padding: 1rem;
}

.col {
  display: flex;
  justify-content: center;
}

.flexCol {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

/* text */

.instructions {
  font-size: 2.4vh;
  font-weight: 500;
  text-align: center;
  padding: 10px;
  height: auto;
}

.listingItem {
  font-size: 2.4vh;
  width: 100%;
  border-bottom: solid rgba(235, 235, 235, 0.822) 1.5px;
  padding: 17px;
}

.title {
  font-size: 3vh;
}

.quantityInput {
  max-width: 100px;
}

/* listingImg */

.imgSize {
  width: 100%;
}

.carousel {
  width: 300px;
  display: inline-block;
}

/* Map */

.map {
  width: 100%;
  min-width: 300px;
}

/* Payment Stuff */

.paymentContainer {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 40px;
  border-radius: 10px;
}

.navButtons {
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
}

.cardGroupSize {
  width: 100%;
  -webkit-margin-start: auto;
  -webkit-margin-end: auto;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.infoIcon {
  position: absolute;
  color: black;
  right: -20px;
  top: 0;

  margin-left: 1px;
  cursor: pointer;
}

.hoverBox {
  top: 0;
  left: 0rem;
  max-width: 20rem;
  z-index: 100;
  position: absolute;
  background-color: white;

  border: 1px solid var(--primary-bg);

  padding: 1rem;
}

.hoverBox1 {
  top: 3rem;
  left: 1rem;
  max-width: 20rem;
  z-index: 100;
  position: absolute;
  background-color: white;

  border: 1px solid var(--primary-bg);

  padding: 1rem;
}

.hoverBoxHeader {
  font-size: 14px;
  font-weight: 600;
}

.hoverBoxP {
  font-size: 12px;
  margin-bottom: 1rem;
}

.colorText {
  color: var(--primary-purple);
  font-weight: 600;
}

.header {
  padding-top: 1em;
  padding-bottom: 1em;
}
.payTitle {
  font-weight: 600;
}

.onHover {
  cursor: pointer;
}

.onHover:hover {
  color: var(--primary-purple);
}

.stripeLogo {
  width: 10em;
}

.payPrice {
  color: var(--primary-purple);
  font-weight: 600;
  text-align: end;
}

.payInfo {
  font-size: 16px;
  font-style: italic;
  color: gray;
  margin-bottom: 1em;
  border-bottom: solid rgba(235, 235, 235, 0.822) 1px;
  max-width: 80%;
}

.marginInline {
  -webkit-margin-start: 1em;
  -webkit-margin-end: 1em;
  margin-inline: 2em;
}

.error {
  color: var(--primary-red-400);
  text-align: center;
  padding: 1em;
  font-size: 16px;
}

.errorDate1 {
  color: var(--primary-red-400);
  font-size: 16px;
}

.paymentButton {
  background-color: var(--primary-purple) !important;
  border: 0 !important;
  margin-top: 30px;
  height: 3rem;

  width: 100%;
}

.paymentButtonDisabled {
  background-color: var(--primary-purple) 4a !important;
  border: 0 !important;
  margin-top: 30px;
  height: 3rem;

  width: 100%;
}

.disabledPaymentButton {
  background-color: var(--primary-purple) 6f !important;
  border: 0 !important;
  margin-top: 30px;
  height: 3rem;
  width: 100%;
}

.submitOrderDisabled {
  width: 100%;
  background-color: var(--primary-purple) 4a !important;
  color: white;
  padding: 0.7em;
  margin-top: 1rem;
  text-align: center;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
  border: 0px;
}

.white {
  margin-left: 0.2rem;
  height: 1.2rem;
  width: 1.2rem;
  color: white !important;
}

.payment {
  background-color: #99a3a6 !important;
}

.protectedBox {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.protectTextBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.learnMore {
  text-decoration: underline;
  display: inline;
}
.learnMore:hover {
  cursor: pointer;
}

.protectHeader {
  font-size: 16px;
  font-weight: 600;
}
.protectBody {
  font-size: 14px;
  color: #6b6b6b;
  line-height: 20px;
  letter-spacing: -0.16px;
}

.shieldIcon {
  margin-top: auto;
  margin-bottom: auto;
  color: #68b368;
  height: 50px;
  width: 50px;
  margin-right: 0.75rem;
}

.cardLogo {
  max-height: 2em;
  max-width: 2em;
  display: inline;
}

.cardDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.cardPreview {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: auto;
  width: 100%;
  border: 0.5px solid rgba(189, 189, 189, 0.627);
  border-radius: 7px;
  margin-bottom: 0.5em;
  padding: 0.7em 0.4rem;
  color: gray;
  position: relative;
}

.cardPreviewActive {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: auto;
  width: 100%;
  border: 0.5px solid var(--primary-purple);
  border-radius: 7px;
  margin-bottom: 0.5em;
  padding: 0.7em 0.4rem;
  color: gray;
  position: relative;
}

.circle {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  width: 20px;
  height: 20px;

  margin-top: auto;
  margin-bottom: auto;
  border-radius: 100%;
  border: 0.5px solid #99a3a6;
}

.circleActive {
  margin-left: 0.75rem;
  margin-right: 0.75rem;

  width: 20px;
  height: 20px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 100%;
  border: 0.5px solid var(--primary-purple);
  background-color: var(--primary-purple);
}

.cardPreview:hover {
  cursor: pointer;
  color: white;
  background-color: rgba(168, 168, 168, 0.398);
  border: 0.5px solid white;
}

.cardPreviewAdd:hover {
  cursor: pointer;
}

.cardPreviewAdd {
  display: flex;
  align-items: center;
  padding: 0.4em;
  color: var(--primary-purple);
}

.cardNumber {
  text-align: left;
  width: 50%;
  margin-left: 2em;
  /* margin-right: 3em; */
  color: #6b6b6b;
}

.addIcon {
  color: var(--primary-purple);
  height: 24px;
  width: 24px;
  display: inline;
  margin-right: 0.75rem;
}

.addText {
  font-weight: 600;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 16px;
  height: 24px;
}

.removeIcon {
  color: #99a3a6;
  height: 25px;
  width: 25px;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  right: 1em;
}

.removeIcon:hover {
  cursor: no-drop;
}

.paymentMethodSection {
  margin-top: 1rem;
  margin-bottom: 0rem;
}

.orPayWith {
  width: 100%;
  text-align: center;
  font-weight: 600;
  padding: 1em;
}

.paypalPayment {
  width: 80%;

  margin: auto;
}

.submitOrder {
  border: 0px;
  width: 100%;
  background-color: var(--primary-purple);
  color: white;
  padding: 0.7em;
  margin-top: 1rem;
  text-align: center;
  border-radius: 7px;
  font-weight: 600;
  cursor: pointer;
}

.wrap {
  display: flex;
  flex-direction: row;
}

@media (max-width: 600px) {
  .checkoutHeader {
    text-align: center;
    font-size: 28px;
    margin-bottom: 3.25rem;
  }

  .col1 {
    width: 100%;
  }

  .chackoutPage {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .leftSide {
    width: 100%;
    padding-bottom: 5rem;
  }

  .datePickerInner {
    max-width: 65px;
  }

  .checkoutSummary {
    width: 100%;
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .mainText1 {
    font-size: 14px;
  }

  .mainText2 {
    font-weight: 600;
    font-size: 13px;
  }

  .timePickerText {
    font-size: 12px;
  }

  .total {
    font-size: 14px;
  }

  .subText {
    font-size: 13px;
  }

  .prices {
    font-size: 13px;
  }

  .listingInfo {
    font-size: 13px;
  }

  .pickUpInfo {
    font-size: 14px;
  }

  .timesInputsBox {
    flex-direction: column;
  }

  .selectBoxActive {
    font-size: 14px;
    height: 3rem;
  }

  .mainTextSummary {
    font-size: 14px;
  }

  .subTextMain {
    font-size: 14px;
  }

  .selectBoxError {
    font-size: 14px;
    height: 3rem;
  }

  .selectBox {
    font-size: 14px;
    height: 3rem;
  }

  .timeSlot {
    font-size: 12px;
  }

  .timeSlotSelected {
    font-size: 12px;
  }

  .wrap {
    display: flex;
    flex-wrap: wrap-reverse;
  }

  .continueButton {
    font-size: 14px;
  }
}
