.badge {
  background-color: var(--primary-bg);
  border-radius: 3px;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.badgeIcon {
  aspect-ratio: 1/1;
}

.img {
  width: 40px;
}

.badgeTextContainer {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.badgeTitle {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-main);
  margin-bottom: 0rem;
}

.badgeText {
  font-size: 14px;
  color: var(--text-body);
  margin-bottom: 0rem;
}

@media (max-width: 600px) {
  .badgeText {
    font-size: 12px;
  }
}
