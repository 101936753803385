.colGap {
	display: flex;
	flex-direction: column;
	row-gap: 3rem;
}

.darkBg {
	/* background-color: #f5f2ee; */
	background-color: var(--primary-bg);
}

.payments {
	max-width: var(--max-width);
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: row;
	padding: 0.5rem 0rem;
	justify-content: flex-end;
}

.paymentText {
	display: flex;
	align-items: center;
	font-size: 14px;
	margin-right: 1rem;
}

.paymentIcons {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.payIcon {
	width: 3.5em;
	height: 1.5em;
	border: 0.5px solid #99a3a6;
	padding: 3px 3px;
	object-fit: contain;
	border-radius: 2px;
}

.bgColor {
	background-color: var(--primary-bg-light);
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.paddingMobile {
	display: flex;
	flex-direction: column;
	gap: inherit;
}

.maxWidthRow {
	max-width: var(--max-width);
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

.column1 {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 50%;
}

.chatHead {
	font-size: 20px;
	font-weight: 500;
	font-family: 'Editorial';

	margin-bottom: 0rem;
}

.column2 {
	width: 45%;
}

@media (max-width: 600px) {
	.payments {
		flex-direction: column;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		gap: 0.5rem;
	}

	.payIcon {
		width: 3rem;
	}

	.bgColor {
		padding-left: 0.75rem;
		padding-right: 0.75rem;
	}

	.paddingMobile {
		padding-left: 0.75rem;
		padding-right: 0.75rem;
	}

	.column1 {
		width: 100%;
	}

	.maxWidthRow {
		flex-direction: column;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		gap: 3rem;
	}

	.chatHead {
		font-size: 18px;
	}

	.column2 {
		width: 100%;
	}
}
