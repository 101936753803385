.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 4.5rem;
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 2rem; */
}

@media (max-width: 600px) {
  .main {
    row-gap: 3rem;
  }
}
