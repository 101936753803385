.carouselContainer {
  display: flex;
  flex-direction: row;
  aspect-ratio: 9/10;
  gap: 1rem;
  width: 100%;
}

/* Large */

.largeImagePreview {
  background-color: var(--primary-bg);
  position: relative;
  border-radius: 2px;

  height: 100%;
  width: 100%;
}

.imageLarge {
  border-radius: 5px;

  width: 100%;
  /* min-height: 616px; */

  height: 100%;
  object-fit: contain;
}

.imageSold {
  border-radius: 5px;

  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0.3 !important;
}

.soldBanner {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--tetradic-blue);
  color: white;
  font-weight: 600;
  opacity: 0.8;
  width: 100%;
  height: 3rem;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 5rem;
  height: 100%;
}

.viewedXTimes {
  border-bottom-right-radius: 5px;
  position: absolute;
  left: 5px;
  top: 5px;
  padding: 0.2rem 0.2rem;
  z-index: 2;
}

/* Small */

.imagePreview {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 1rem;
  list-style: none;
  padding: 0rem;
  margin: 0rem;
  /* max-height: calc(100% - 4rem); */
}

.wrapperContainer {
  margin-right: 1.25rem;
  width: 80px;
  height: 2rem;
}

.scroll {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.arrow {
  width: 40%;
}

.scroll:hover {
  cursor: pointer;
}
.wrapp {
  position: relative;
  height: 100%;
  /* min-height: 616px; */
}

.image {
  border-radius: 2px;
  width: 5rem;
  height: 5rem;
  object-fit: cover;
  border: 1px solid var(--primary-border);
  /* margin-bottom: 1rem; */
}

.nonActive {
  border-radius: 2px;
  width: 5rem;
  height: 5rem;
  object-fit: cover;
  opacity: 0.5 !important;
  /* margin-bottom: 1rem; */
}

.image:hover {
  cursor: pointer;
}

/* Next & Previos */

.next {
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(76, 76, 76, 0.28);

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 1;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.previous {
  cursor: pointer;

  box-shadow: 0px 2px 4px rgba(76, 76, 76, 0.28);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 1;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.fullButtonWhite {
  width: 1.5rem;
  color: var(--text-body);
  height: 1.5rem;
}
.fullButton {
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}

.fullButton:hover {
  background-color: white;
  transition: all 0.2s ease-in-out;
}

.fullScreenModal {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: none !important;
}

@media (max-width: 600px) {
  .wrapper {
    display: none;
  }

  .largeImagePreview {
    width: 100%;
    border-radius: 0px;
  }
  .imageLarge {
    border-radius: 0px;

    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .imageSold {
    border-radius: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .carouselContainer {
    aspect-ratio: 10/10;
  }
}

.dotsContainer {
  width: fit-content;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  max-width: 100%;
  width: 100%;
  align-items: center;
}

.dot {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  min-width: 0.3rem;
  min-height: 0.3rem;
  border-radius: 100%;
  background-color: white;
  width: 0.3rem;
  height: 0.3rem;
  transition: 0.2s linear;
}

.dotActive {
  margin-left: 0.2rem;
  margin-right: 0.2rem;
  border-radius: 100%;
  background-color: white;
  min-width: 0.5rem;
  min-height: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  transition: 0.2s linear;
}

@media (min-width: 600px) {
  .dotsContainer {
    display: none;
  }
}
