.mainContainer {
  max-width: 1452px;
  padding-left: 5rem;
  padding-right: 5rem;
  margin-left: auto;
  margin-right: auto;
}

.deliveryInfo {
  margin-bottom: 1em;
  position: relative;
  cursor: pointer;
  background-color: white;
}

.deliveryInfoDisabled {
  margin-bottom: 1em;
  position: relative;
  background-color: white;
}

.buttonContainer {
  padding: 0rem;
  padding-top: 0rem;
}

.continueButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 100%;
  height: 3rem;
  background-color: var(--primary-purple);
  color: white;
  margin-top: 1rem;
  border: 0;
  border-radius: 3px;
}

.continueButton:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
  transform: translateY(-1px);
}

.rightSection {
  display: flex;
  height: fit-content;

  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;

  flex-direction: column;
}

.leftSection {
  width: 60%;
  display: inline-block;
}

.checkMark {
  color: var(--primary-purple);
}

.mainText {
  background-color: var(--primary-bg);

  border: 1px solid var(--primary-border);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0rem;
}

.mainTextActive {
  background-color: var(--primary-bg);

  border: 1px solid var(--primary-purple);
  border-radius: 8px;
  color: #222222;

  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0rem;
}

.mainTextSummary {
  text-align: start;
  font-weight: 500;
  margin-bottom: 1rem;
  font-size: 16px;
}

.timesInputsText {
  padding: 1rem;
  margin-top: 1rem;
  display: flex;
  border-top: 1px solid #159389;

  flex-direction: column;
}

.cartPage {
  max-width: 1452px;
  padding-left: 8.75rem;
  padding-right: 8.75rem;
  margin-left: auto;
  margin-right: auto;
}

.bg {
  background-color: var(--primary-bg);
  min-height: 100vh;
}

.sellerBatch {
  margin-bottom: 2rem;
  margin-top: 1rem;
  background-color: white;
  position: relative;
}

.cartHeader {
  padding-top: 3.25rem;
  margin-bottom: 2rem;

  font-size: 32px;
  font-weight: 600;
}
.sellerBatches {
  width: 60%;
  display: inline-block;
}

.cartSummary {
  width: 35%;
  margin-left: 2.5%;
  display: inline-block;

  min-height: 10em;
  vertical-align: top;
}

.deliveryInput {
  background-color: white;
  min-height: 3em;
  margin-bottom: 1em;
  padding: 1rem;
}

.deliveryInputError {
  background-color: white;
  min-height: 3em;
  margin-bottom: 1em;
  padding: 1rem;
  border: 1px dashed var(--primary-red-400);
}

.cartOverview {
  padding: 1rem;
  background-color: white;
  margin-bottom: 1rem;
}

.listingPicture {
  max-height: 4em;
  max-width: 4em;
}

.listingTitle {
  color: black;
  font-weight: 500;

  font-size: 14px;
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.listingPrice {
  display: inline-block;
  /* width: 100%; */
  font-weight: 500;
  margin-left: auto;
}

.listingPrice1 {
  display: inline-block;
  /* width: 100%; */
  font-weight: 500;
  margin-left: auto;
  margin-right: 1.5rem;
}

.qty {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: auto;
  padding-left: 0.5rem;
}

.add {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #99a3a6;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  border: 0.5px solid #99a3a6;
  padding: 5px;
  cursor: pointer;
}

.totalQty {
  color: #99a3a6;
  margin-left: 0.7rem;
  margin-right: 0.7rem;
}

.quantityContainer {
  color: black;
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1em;
}

.imgWrapper {
  position: relative;
  border: 1px solid var(--primary-bg);
  border-radius: 5px;
  display: inline;
}

.cartListingImg {
  position: relative;
  object-fit: cover;
  height: 100px;
  width: 100px;
  border-radius: 5px;
}

.cartListingImgNonActive {
  position: relative;
  object-fit: cover;
  height: 100px;
  width: 100px;
  border-radius: 5px;
  opacity: 0.4;
}

.prices {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.sold {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--tetradic-blue);
  text-align: center;
  color: white;
  font-weight: 500;
  opacity: 0.8;
  width: 100%;
}

.remove {
  margin-top: auto;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.639);
  text-decoration: underline;
  font-size: 12px;
}

.listingContainer {
  border-bottom: 1px solid var(--primary-bg);
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
}
.listingContainerLast {
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
}

.checkoutHeader {
  padding-top: 3.25rem;
  margin-bottom: 2rem;

  font-size: 32px;
  font-weight: 500;
}

.goToCheckout {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-purple);
  width: 100%;
  color: white;
  font-weight: 500;
  min-height: 3em;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
}

.goToCheckoutDisabled {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-purple) 86;
  width: 100%;
  color: white;
  font-weight: 500;
  min-height: 3em;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
}

.goToCheckout:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0 3px 9px 0;
  transform: translateY(-1px);
}

.error {
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  padding: 1rem;
  width: fit-content;
  margin-bottom: 0rem;
  color: var(--primary-red-400);
}

.round {
  border: 1px solid #99a3a6;
  width: 1.5rem;
  height: 1.5rem;
  background-color: white;
  border-radius: 100%;
  display: inline-block;
  margin-right: 1rem;
}

.roundActive {
  margin-right: 1rem;

  border: 1px solid #99a3a6;
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--primary-green-500);
  display: inline-block;
  border-radius: 100%;
}

.deliveryOption {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0em 1rem;
}

.enterAddress {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1em 1rem;
}
.chooseOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  padding: 0em 1rem;
  position: relative;
}

.space {
  padding-top: 1rem;
}

.arrowForward {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1em;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.639);
}
.arrowForwardActive {
  transform: rotate(90deg);
}

.selectBox1 {
  margin-top: 0rem;

  background-color: var(--primary-green-100);
  border: 1px solid var(--primary-purple);

  padding: 1rem 0rem;
  /* vertical-align: middle; */
  border-radius: 4px;
  padding-bottom: 0rem;
}

.selectBoxError {
  margin-top: 0rem;

  background-color: var(--primary-green-100);
  border-radius: 4px;

  transition: 0.5s linear;
  border: 1px dashed var(--primary-red-400);
}
.selectBox {
  margin-top: 0rem;
  background-color: var(--primary-green-100);
  border-radius: 4px;
  transition: 0.5s linear;
  border: 1px solid var(--primary-purple);
}

.infoIcon {
  width: 24px;
  margin-right: 1rem;
  height: 24px;
}

.errorDate1 {
  color: var(--primary-red-400);
  font-size: 16px;
}

.flexCol {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.timePickerText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-bottom: 0rem;
}

.pointerError {
  cursor: pointer;
  position: relative;
  color: black;
  min-width: 100%;
  margin-block: 5px;
  padding: 10px;
  background-color: var(--primary-bg);
  border: 1px dashed var(--primary-red-400);
}

.pointer {
  cursor: pointer;
  position: relative;
  color: black;
  min-width: 100%;
  margin-block: 5px;
  padding: 10px;
  background-color: white;
  border: 1px solid rgb(232 234 239);
}

.space1 {
  margin-right: 1rem;
}

.timesInputsBox {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

.infoText {
  font-size: 16px;
  margin-bottom: 0rem;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

.right {
  margin-left: auto;
  margin-right: 2rem;
  width: 1rem;
  height: 1rem;
}

.infoTextSmall {
  text-align: left;
  font-size: 13px;
  color: #222222;
  font-weight: 300;
  padding: 0em 3.5rem;
  margin-bottom: 0rem;
}

.deliveryInfo {
  width: 100%;
  text-align: center;
}

.smallDeliveryInfo {
  color: #222222;
  font-weight: 300;
  font-size: 13px;
}

.imgLocation {
  width: 1rem;
  height: 1.5rem;
  margin-right: 1rem;
}

.deliveryContainer {
  font-weight: 500;
  display: flex;
  align-items: center;
}

.subText {
  color: #9f9f9f;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--primary-bg);
}

.muteText {
  color: #9f9f9f;
  margin-bottom: 0rem;
  margin-top: 0.5rem;
  font-size: 14px;
  line-height: 1.5;
}

.subTextMain {
  display: flex;
  margin-bottom: 0rem;
  align-items: center;
  justify-content: space-between;
}

.summarySection {
  border-bottom: 1px solid var(--primary-bg);
}

.underLine {
  text-decoration: underline;
  color: #9f9f9f;
}

.underLine:hover {
  color: black;
}

.payments {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}
.payIconBox {
  margin-right: 1ch;
}
.payIcon {
  width: 4em;
  height: 2em;
  border: 0.5px solid #99a3a6;
  padding: 3px 3px;
  border-radius: 2px;
}

.placeHolderDiv {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100%;
}

.placeholder1 {
  width: 60%;
  height: 90vh;
  background-size: 100% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(0, 0%, 100%) 0%,
    hsl(0, 0%, 95%) 50%,
    hsl(0, 0%, 100%) 100%
  );
  animation: anim 2s infinite;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.placeholder2 {
  background-size: 100% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(0, 0%, 100%) 0%,
    hsl(0, 0%, 95%) 50%,
    hsl(0, 0%, 100%) 100%
  );
  animation: anim 2s infinite;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  height: 20rem;
  margin-left: 3.5%;
  margin-bottom: 1rem;
}
.col1 {
  display: flex;
  flex-direction: column;
  width: 35%;
  height: 80vh;
}

@keyframes anim {
  0% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}

.placeholder3 {
  background-size: 100% 200%;
  background-image: repeating-linear-gradient(
    -45deg,
    hsl(0, 0%, 100%) 0%,
    hsl(0, 0%, 95%) 50%,
    hsl(0, 0%, 100%) 100%
  );
  animation: anim 2s infinite;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  height: 10rem;
  margin-left: 3.5%;
  margin-bottom: 1rem;
}

.wrap {
  display: flex;
  flex-direction: row;
}

/* rightSide */

.whiteBox {
  padding: 1rem;
  padding-bottom: 0rem;
}

.listingContainer {
  position: relative;
  padding: 1rem;
  display: flex;
  border-bottom: 1px solid var(--primary-bg);
}

.listingImg {
  position: relative;
  object-fit: cover;
  width: 4rem;
  height: 4rem;
  min-width: 4rem !important;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.infoSec {
  width: 100%;
  padding-left: 1em;
  margin-right: 1em;
  display: flex;
  flex-direction: column;
}

.listingPrice {
  display: inline-block;
  /* width: 100%; */
  font-weight: 500;
  margin-left: auto;
  padding-right: 2rem;
}

.cartOverview {
  padding: 1rem;
  background-color: white;
  margin-bottom: 1rem;
}

.mainTextSummaryCart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
}

.listingPicture {
  max-height: 4em;
  max-width: 4em;
}

.price {
  color: rgb(125 128 140);
  /* width: 100%; */
  font-weight: 400;
  margin-left: auto;
}

.listingExtraInfo {
  color: rgb(31 33 35);
  font-size: 12px;
  margin-bottom: 0rem;
}

.listingTitle {
  color: black;
  font-weight: 500;
  font-size: 14px;
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

@media (max-width: 600px) {
  .mainContainer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .cartHeader {
    text-align: center;
    font-size: 28px;
    margin-bottom: 3.25rem;
  }

  .checkoutHeader {
    text-align: center;
    font-size: 28px;
    margin-bottom: 3.25rem;
  }

  .timesInputsBox {
    display: flex;
    flex-direction: column;
  }

  .wrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap-reverse;
  }

  .leftSection {
    width: 100%;
  }

  .rightSection {
    width: 100%;
    margin-left: 0rem;

    margin-top: 1rem;
  }

  .placeholder1 {
    width: 100%;
  }
  .col1 {
    width: 100%;
  }

  .deliveryContainer {
    font-size: 14px;
  }

  .subText {
    font-size: 14px;
  }

  .subTextMain {
    font-size: 14px;
  }

  .listingTitle {
    font-size: 14px;
    font-weight: 400;
  }

  .listingPrice {
    font-size: 14px;
  }

  .listingPrice1 {
    font-size: 14px;
  }

  .deliveryOption {
    font-size: 14px;
  }

  .muteText {
    font-size: 13px;
  }

  .cartPage {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }

  .sellerBatches {
    width: 100%;
  }

  .cartSummary {
    width: 100%;
    margin-left: 0rem;
  }
}
