.container {
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  border: 1px solid var(--primary-border);
  max-height: 40em;
  width: 100%;
}

.profileImgContainer {
  display: flex;
  flex-direction: row;
}

.commentDeleted {
  font-style: italic;
  color: #424242;
  font-size: small;
}

.name {
  margin-left: 0.5rem;
  font-weight: 600;
  margin-bottom: 0rem;
}

.authorNameDescription {
  display: flex;
  align-items: center;
}

.textContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.8em;
  background: white;
  font-size: 15px;
}

.inputDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
  border-top: 1px solid var(--primary-bg);
  background-color: white;
  position: relative;
}

.input {
  outline: none;
  position: relative;
  width: calc(100%);
  margin: auto;
  height: 40px;
  background-color: white;
  padding: 0.5em 1em;
  margin-left: 0.5rem;
  border: 1px solid var(--primary-border);
  padding-right: 70px;
  border-radius: 2em;
  font-size: 14px;
}
.inputDiv ::placeholder {
  color: #acb1b4;
  font-size: 14px;
}
.sendButton2 {
  border: none;
  background-color: var(--primary-purple);
  color: white;
  border-radius: 4px;
  width: auto;
  height: 35px;
  padding-left: 0.7em;
  padding-right: 0.7rem;
  margin-left: 0.5em;
}

.sendButton2Deactive {
  background-color: #c6ced6;
  border: none;

  color: white;
  border-radius: 4px;
  width: auto;
  height: 30px;
  padding-left: 0.7em;
  padding-right: 0.7rem;
  margin-left: 0.5em;
}

.sendButton2Deactive:hover {
  cursor: not-allowed !important  ;
}

.sendButton2:hover {
  opacity: 0.9;
}

.sendButton {
  border: none;
  background-color: var(--primary-purple);
  color: white;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  margin-left: 0.5em;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  position: absolute;
  right: 1rem;
  transition: all 0.2s ease-in-out;
}

.sendButton:hover {
  opacity: 0.8;
}

.sendButtonDeactive {
  border: none;
  background-color: var(--primary-purple);
  opacity: 0.2;
  color: white;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  margin-left: 0.5em;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  position: absolute;
  right: 1rem;
  transition: all 0.2s ease-in-out;
}

.sendIcon {
  width: 80%;
  height: 80%;
}

.userName {
  max-width: 48px;
  text-align: center;
  font-size: 10px;
  margin-bottom: 0rem;
  font-weight: 400;
  margin-top: 0.2rem;
}

.sendButton:hover {
  opacity: 0.8;
}

.sendButtonDeactive:hover {
  cursor: not-allowed !important;
}

.comment {
  display: flex;
  flex-direction: row;
  margin-top: 0.5em;
  max-width: 80%;
  margin-bottom: 0.5rem;
  position: relative;
}

.commentTriangle {
  position: absolute;
  left: -6px;
  top: 17px;

  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;

  border-right: 10px solid var(--primary-bg);
}

.commentTriangleColor {
  position: absolute;
  left: -6px;
  top: 17px;

  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;

  border-right: 10px solid var(--primary-red-100);
}

.replyTriangle {
  position: absolute;
  right: -6px;
  top: 17px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;

  border-left: 10px solid var(--primary-bg);
}

.replyTriangleColor {
  position: absolute;
  right: -6px;
  top: 17px;

  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;

  border-left: 10px solid var(--primary-red-100);
}

.col {
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
}
.col1 {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
}

.clipText {
  max-width: inherit;
  overflow-wrap: break-word;
}

.infoBanner {
  color: #555555;
  font-size: 10px;
  margin-left: 1rem;
  text-align: end;
}

.commentDiv {
  position: relative;
  width: 100%;
  min-height: 15.7em;

  max-height: 15.7em;
  overflow-y: visible;
  overflow-x: hidden;
  padding: 1em 1rem;
  background-color: white;
  border-top: 1px solid rgb(242, 242, 242);
}

.mobile {
  display: none;
}

.commentDate {
  color: #5e5e5e;
  /* position: absolute; */
  /* margin-right: 1em; */
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 13px;
  margin-top: auto;
  white-space: nowrap;

  /* display: inline; */
}

.commentPicture {
  max-width: 3em;
  min-width: 3em;
  min-height: 3em;
  max-height: 3em;
  object-fit: cover;
  border-radius: 50% !important;
}

.commentPictureDefault {
  max-width: 3em;
  min-width: 3em;
  min-height: 3em;
  max-height: 3em;
  padding: 5px;
  object-fit: scale-down;
  border-radius: 50% !important;
  border: 1px solid var(--primary-bg);
}

.text1 {
  display: flex;
  align-items: center;
  box-shadow: 0 1px 0.5px rgb(11 20 26 / 13%);
  font-size: 14px;
  border-radius: 3px;
  background: var(--primary-bg);
  color: black;

  width: 100%;
  text-align: left;
  /* overflow-x: scroll; */
  padding: 0.4rem 0.7rem;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 0rem;
  font-weight: 400;
}

.colorText1 {
  position: relative;

  display: flex;
  align-items: center;
  box-shadow: 0 1px 0.5px rgb(11 20 26 / 13%);
  font-size: 14px;
  border-radius: 3px;
  background: var(--primary-red-100);
  color: var(--text-main);
  width: 100%;
  font-weight: 400;

  text-align: left;
  padding: 0.4rem 0.7rem;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 0rem;
}

.text {
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 0.5px rgb(11 20 26 / 13%);
  font-size: 14px;
  border-radius: 3px;
  background: var(--primary-bg);
  color: black;
  font-weight: 400;

  width: 100%;
  text-align: left;
  /* overflow-x: scroll; */
  padding: 0.4rem 0.7rem;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 0rem;
}

.colorText {
  position: relative;

  display: flex;
  align-items: center;
  box-shadow: 0 1px 0.5px rgb(11 20 26 / 13%);
  font-size: 14px;
  border-radius: 3px;
  background: var(--primary-red-100);
  color: var(--text-main);
  font-weight: 400;

  width: 100%;
  text-align: left;
  padding: 0.4rem 0.7rem;
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 0rem;
}

.commentText {
  position: relative;

  display: flex;
  height: 100%;
  flex-direction: row;
  width: 100%;
}

.businessPic {
  display: inline-flex;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  width: 3em !important;
  height: 3em !important;
  min-width: 3rem;
  min-height: 3rem;
  border-radius: 99999px;
  /* border: 1px solid var(--primary-border); */
  color: white;
  background-color: var(--tetradic-blue);
}

.businessIcon {
  width: 70% !important;
  height: 70% !important;
}

/* Reply */
.box {
  display: flex;
  flex-direction: column;
}

.replyContainer {
  display: flex;
  align-items: center;
  margin-top: 0.5em;
  text-align: center;
  width: 100%;
}

.replyButtonContainer {
  margin-top: 0.5rem;

  display: flex;
  flex-direction: row;
}

.replyButton {
  border-radius: 8px;
  display: inline-block;
  font-size: 12px;
  font-style: italic;
  background-color: var(--primary-purple);
  color: white;
  width: fit-content;
  padding: 2px 5px;
  transition: 0.2s ease;
}

.replyButton:hover {
  cursor: pointer;
  opacity: 0.8;
}

.deleteButton {
  display: inline-block;
  border-radius: 8px;
  font-size: 12px;
  font-style: italic;
  color: var(--text-body);
  text-decoration: underline;
  /* display: inline; */
  padding: 2px 5px;
  margin-left: 0.2rem;
  transition: 0.2s ease;
  cursor: pointer;
}

.deleteButton:hover {
  cursor: pointer;
  color: var(--text-main);
  /* display: inline; */
}
/* .lowerRow {
	width: 100%;
} */

.input2 {
  outline: none;
  height: 2.5rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: rgba(0, 0, 0, 0.185) 0px 4px 15px 0px;
  border: 0.02px solid rgba(0, 0, 0, 0.144);
  border-radius: 10px;
  padding: 0.5em;
  display: inline-block;
  /* overflow: auto;
	max-height: 5em; */
}

/* input {
	color: none;
	background-color: none;
	border-style: none;
	border-width: 1px;
} */

.example {
  color: gray;
  font-style: italic;
  font-size: 0.9em;
  margin-top: 1em;
}

/* Button! */

.bell2 {
  color: white;
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

.bell {
  color: white;
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
  position: absolute;
  right: 10px;
}

.reply {
  margin-left: auto;
  justify-content: flex-end;
  max-width: 80%;
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
  position: relative;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.mb {
  margin-top: 0;
  margin-bottom: 0;
}

@media (max-width: 600px) {
  .container {
    box-shadow: none;
    width: 100%;
  }
  .textContainer {
    padding: 1em;
    padding-top: 1em;
  }

  .mobile {
    display: inline;
  }
  .comment {
    max-width: 95%;
  }
  .reply {
    max-width: 95%;
  }
}

.sendPic {
  display: flex;
  color: var(--text-body);
  width: 25px;
}
.sentImage {
  height: 100px;
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; /* cover the entire width of the viewport */
  height: 100%; /* cover the entire height of the viewport */
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  z-index: 1000; /* high value to ensure modal is on top */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_content {
  background-color: #fff;
  padding: 20px;
  position: relative;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  box-shadow: 0px 0px 15px rgba(143, 143, 143, 0.479);
}

.modal_content img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.close_btn {
  position: absolute;
  right: 4px;
  top: -7px;
  cursor: pointer;
  font-size: x-large;
  font-weight: bold;
  float: right;
  height: 0;
  background-color: #fff;
}
.sendReplyPic {
  margin: 10px;
  width: 40px;
}
